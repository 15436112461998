<template>
  <main-document :tabs="tabs" />
</template>

<script>
import tabController from '@/tabController'

export default {
  components: {
    MainDocument: () => import('@/components/main-document')
  },
  activated () {
    this.$store.commit('setModule', {
      name: this.$t('t.Provider'),
      icon: 'i.Providers'
    })
  },
  deactivated () {
    this.$removeFromKeepAliveCache()
    this.$destroy()
  },
  computed: {
    tabs () {
      return [
        tabController.create(tabController.providerTab(), { title: this.$t('t.Providers'), icon: '', documentId: this.$route.params.id })
      ]
    }
  }
}
</script>
